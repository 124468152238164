import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Location, LocationSearchFilter } from '../../domain/Location';
import { ListResults } from '../../common/List/List';
import { LocationsSearchParams } from './service';

export const fetchLocationsStart = () => ({
  type: actionTypes.FETCH_LOCATIONS_START,
});

export const fetchLocationsSuccess = (
  locationsList: ListResults<Location>,
) => ({
  type: actionTypes.FETCH_LOCATIONS_SUCCESS,
  locationsList,
});

export const fetchLocationsFail = (locationsError: HttpError) => ({
  type: actionTypes.FETCH_LOCATIONS_FAIL,
  locationsError,
});

export const fetchLocationSearchFilterStart = () => ({
  type: actionTypes.FETCH_LOCATION_SEARCH_FILTER_START,
});

export const fetchLocationSearchFilterSuccess = (
  locationSearchFilter: LocationSearchFilter,
) => ({
  type: actionTypes.FETCH_LOCATION_SEARCH_FILTER_SUCCESS,
  locationSearchFilter,
});

export const fetchLocationSearchFilterFail = (
  locationSearchFilterError: HttpError,
) => ({
  type: actionTypes.FETCH_LOCATION_SEARCH_FILTER_FAIL,
  locationSearchFilterError,
});

export const searchLocationsStart = () => ({
  type: actionTypes.SEARCH_LOCATIONS_START,
});

export const searchLocationsSuccess = (searchLocations: Location[]) => ({
  type: actionTypes.SEARCH_LOCATIONS_SUCCESS,
  searchLocations,
});

export const searchLocationsFail = (searchLocationsError: HttpError) => ({
  type: actionTypes.SEARCH_LOCATIONS_FAIL,
  searchLocationsError,
});

export const setLocationsSearchParams = (
  searchLocationsParams: LocationsSearchParams,
) => ({
  type: actionTypes.SET_LOCATIONS_SEARCH_PARAMS,
  searchLocationsParams,
});

export const fetchLocationStart = () => ({
  type: actionTypes.FETCH_LOCATION_START,
});

export const fetchLocationSuccess = (location: Location) => ({
  type: actionTypes.FETCH_LOCATION_SUCCESS,
  location,
});

export const fetchLocationFail = (locationError: HttpError) => ({
  type: actionTypes.FETCH_LOCATION_FAIL,
  locationError,
});

export const fetchLocationForSearchStart = () => ({
  type: actionTypes.FETCH_LOCATION_FOR_SEARCH_START,
});

export const fetchLocationForSearchSuccess = (locationForSearch: Location) => ({
  type: actionTypes.FETCH_LOCATION_FOR_SEARCH_SUCCESS,
  locationForSearch,
});

export const fetchLocationForSearchFail = (
  locationForSearchError: HttpError,
) => ({
  type: actionTypes.FETCH_LOCATION_FOR_SEARCH_FAIL,
  locationForSearchError,
});

export const fetchPublicLocationStart = () => ({
  type: actionTypes.FETCH_PUBLIC_LOCATION_START,
});

export const fetchPublicLocationSuccess = (publicLocation: Location) => ({
  type: actionTypes.FETCH_PUBLIC_LOCATION_SUCCESS,
  publicLocation,
});

export const fetchPublicLocationFail = (publicLocationError: HttpError) => ({
  type: actionTypes.FETCH_PUBLIC_LOCATION_FAIL,
  publicLocationError,
});

export const fetchLocationGalleryStart = () => ({
  type: actionTypes.FETCH_LOCATION_GALLERY_START,
});

export const fetchLocationGallerySuccess = (locationGallery: Location) => ({
  type: actionTypes.FETCH_LOCATION_GALLERY_SUCCESS,
  locationGallery,
});

export const fetchLocationGalleryFail = (locationGalleryError: HttpError) => ({
  type: actionTypes.FETCH_LOCATION_GALLERY_FAIL,
  locationGalleryError,
});

export const fetchLocationOptionsStart = () => ({
  type: actionTypes.FETCH_LOCATION_OPTIONS_START,
});

export const fetchLocationOptionsSuccess = (locationOptions: Location[]) => ({
  type: actionTypes.FETCH_LOCATION_OPTIONS_SUCCESS,
  locationOptions,
});

export const fetchLocationOptionsFail = (locationOptionsError: HttpError) => ({
  type: actionTypes.FETCH_LOCATION_FAIL,
  locationOptionsError,
});

export const createLocationStart = () => ({
  type: actionTypes.CREATE_LOCATION_START,
});

export const createLocationSuccess = (createdLocation: Location) => ({
  type: actionTypes.CREATE_LOCATION_SUCCESS,
  createdLocation,
});

export const createLocationFail = (locationCreateError: HttpError) => ({
  type: actionTypes.CREATE_LOCATION_FAIL,
  locationCreateError,
});

export const updateLocationStart = () => ({
  type: actionTypes.UPDATE_LOCATION_START,
});

export const updateLocationSuccess = () => ({
  type: actionTypes.UPDATE_LOCATION_SUCCESS,
});

export const updateLocationFail = (locationUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_LOCATION_FAIL,
  locationUpdateError,
});

export const copyLocationStart = () => ({
  type: actionTypes.COPY_LOCATION_START,
});

export const copyLocationSuccess = () => ({
  type: actionTypes.COPY_LOCATION_SUCCESS,
});

export const copyLocationFail = (locationCopyError: HttpError) => ({
  type: actionTypes.COPY_LOCATION_FAIL,
  locationCopyError,
});

export const deleteLocationStart = () => ({
  type: actionTypes.DELETE_LOCATION_START,
});

export const deleteLocationSuccess = () => ({
  type: actionTypes.DELETE_LOCATION_SUCCESS,
});

export const deleteLocationFail = (locationDeleteError: HttpError) => ({
  type: actionTypes.DELETE_LOCATION_FAIL,
  locationDeleteError,
});

export const selectLocation = (selectedLocation: number) => ({
  type: actionTypes.SELECT_LOCATION,
  selectedLocation,
});

export const setLocationSortOrdersStart = () => ({
  type: actionTypes.SET_LOCATION_SORT_ORDER_START,
});

export const setLocationSortOrdersSuccess = () => ({
  type: actionTypes.SET_LOCATION_SORT_ORDER_SUCCESS,
});

export const setLocationSortOrdersFail = (
  locationSortOrdersError: HttpError,
) => ({
  type: actionTypes.SET_LOCATION_SORT_ORDER_FAIL,
  locationSortOrdersError,
});

export const resetLocationStore = () => ({
  type: actionTypes.RESET_LOCATION_STORE,
});

export const resetLocationsSearch = () => ({
  type: actionTypes.RESET_LOCATIONS_SEARCH,
});

export const routes = {
  homepage: '/:locale',
  search: '/:locale/search',
  dynamicPage: '/:locale/:slug',
  locationPage: '/:locale/:city/:location',
  contacts: '/:locale/contacts',
  checkout: '/:locale/checkout',
  login: '/login',
  admin: '/admin',
  users: {
    list: '/admin/users',
    edit: '/admin/users/:id',
    create: '/admin/users/new',
  },
  blogs: {
    list: '/admin/blogs',
    edit: '/admin/blogs/:id',
    create: '/admin/blogs/new',
  },
  faq: {
    list: '/admin/faq',
    edit: '/admin/faq/:id',
    create: '/admin/faq/new',
  },
  cities: {
    list: '/admin/cities',
    create: '/admin/cities/new',
    edit: '/admin/cities/:id',
  },
  locations: {
    list: '/admin/locations',
    create: '/admin/locations/new',
    edit: '/admin/locations/:id',
  },
  locationGroups: {
    list: '/admin/location-groups',
    create: '/admin/location-groups/new',
    edit: '/admin/location-groups/:id',
  },
  rooms: {
    list: '/admin/rooms',
    create: '/admin/rooms/new',
    edit: '/admin/rooms/:id',
  },
  ages: {
    list: '/admin/ages',
    create: '/admin/ages/new',
    edit: '/admin/ages/:id',
  },
  programs: {
    list: '/admin/programs',
    edit: '/admin/programs/:id',
  },
  amenities: {
    list: '/admin/amenities',
  },
  enquiries: {
    list: '/admin/enquiries',
  },
  translations: {
    list: '/admin/translations',
  },
  reviews: '/admin/reviews',
};

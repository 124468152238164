export const FETCH_BLOGS_START = 'FETCH_BLOGS_START';
export const FETCH_BLOGS_SUCCESS = 'FETCH_BLOGS_SUCCESS';
export const FETCH_BLOGS_FAIL = 'FETCH_BLOGS_FAIL';

export const FETCH_BLOG_START = 'FETCH_BLOG_START';
export const FETCH_BLOG_SUCCESS = 'FETCH_BLOG_SUCCESS';
export const FETCH_BLOG_FAIL = 'FETCH_BLOG_FAIL';

export const CREATE_BLOG_START = 'CREATE_BLOG_START';
export const CREATE_BLOG_SUCCESS = 'CREATE_BLOG_SUCCESS';
export const CREATE_BLOG_FAIL = 'CREATE_BLOG_FAIL';

export const UPDATE_BLOG_START = 'UPDATE_BLOG_START';
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS';
export const UPDATE_BLOG_FAIL = 'UPDATE_BLOG_FAIL';

export const DELETE_BLOG_START = 'DELETE_BLOG_START';
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';
export const DELETE_BLOG_FAIL = 'DELETE_BLOG_FAIL';

export const FETCH_TAGS_START = 'FETCH_TAGS_START';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAIL = 'FETCH_TAGS_FAIL';

export const RESET_BLOG_STORE = 'RESET_BLOG_STORE';

export const LOGOUT = 'LOGOUT';

export type BlogActionTypes =
  | typeof FETCH_BLOGS_START
  | typeof FETCH_BLOGS_SUCCESS
  | typeof FETCH_BLOGS_FAIL
  | typeof FETCH_BLOG_START
  | typeof FETCH_BLOG_SUCCESS
  | typeof FETCH_BLOG_FAIL
  | typeof CREATE_BLOG_START
  | typeof CREATE_BLOG_SUCCESS
  | typeof CREATE_BLOG_FAIL
  | typeof UPDATE_BLOG_START
  | typeof UPDATE_BLOG_SUCCESS
  | typeof UPDATE_BLOG_FAIL
  | typeof DELETE_BLOG_START
  | typeof DELETE_BLOG_SUCCESS
  | typeof DELETE_BLOG_FAIL
  | typeof FETCH_TAGS_START
  | typeof FETCH_TAGS_SUCCESS
  | typeof FETCH_TAGS_FAIL
  | typeof RESET_BLOG_STORE
  | typeof LOGOUT;

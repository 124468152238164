import * as actionTypes from './actionTypes';
import { LocationActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Location, LocationSearchFilter } from '../../domain/Location';
import { ListResults } from '../../common/List/List';
import { LocationsSearchParams } from './service';

export type LocationStateType = {
  locationsList: ListResults<Location> | null;
  locationsLoading: boolean;
  locationsError: HttpError;
  locationSearchFilter: LocationSearchFilter | null;
  locationSearchFilterLoading: boolean;
  locationSearchFilterError: HttpError;
  searchLocations: Location[];
  searchLocationsLoading: boolean;
  searchLocationsError: HttpError;
  searchLocationsParams: LocationsSearchParams | null;
  locationsListUpdateNeeded: boolean;
  locationCreateLoading: boolean;
  locationCreateError: HttpError;
  locationCreateSuccess: boolean;
  createdLocation: Location | null;
  locationUpdateLoading: boolean;
  locationUpdateError: HttpError;
  locationUpdateSuccess: boolean;
  locationCopyLoading: boolean;
  locationCopyError: HttpError;
  locationCopySuccess: boolean;
  locationDeleteLoading: boolean;
  locationDeleteError: HttpError;
  locationDeleteSuccess: boolean;
  location: Location | null;
  locationLoading: boolean;
  locationError: HttpError;
  publicLocation: Location | null;
  publicLocationLoading: boolean;
  publicLocationError: HttpError;
  locationGallery: Location | null;
  locationGalleryLoading: boolean;
  locationGalleryError: HttpError;
  locationOptions: Location[];
  locationOptionsLoading: boolean;
  locationOptionsError: HttpError;
  selectedLocation: number | null;
  locationsForSearch: Location[];
  locationForSearchLoading: boolean;
  locationForSearchError: HttpError;
  locationSortOrdersLoading: boolean;
  locationSortOrdersError: HttpError;
  locationSortOrdersSuccess: boolean;
};

export type LocationActionType = LocationStateType & {
  type: LocationActionTypes;
  locationForSearch: Location;
};

export const initialState: LocationStateType = {
  locationsList: null,
  locationsLoading: true,
  locationsError: null,
  locationSearchFilter: null,
  locationSearchFilterLoading: true,
  locationSearchFilterError: null,
  searchLocations: [],
  searchLocationsLoading: false,
  searchLocationsError: null,
  searchLocationsParams: null,
  locationsListUpdateNeeded: false,
  locationCreateLoading: false,
  locationCreateError: null,
  locationCreateSuccess: false,
  createdLocation: null,
  locationUpdateLoading: false,
  locationUpdateError: null,
  locationUpdateSuccess: false,
  locationCopyLoading: false,
  locationCopyError: null,
  locationCopySuccess: false,
  locationDeleteLoading: false,
  locationDeleteError: null,
  locationDeleteSuccess: false,
  location: null,
  locationLoading: false,
  locationError: null,
  publicLocation: null,
  publicLocationLoading: false,
  publicLocationError: null,
  locationGallery: null,
  locationGalleryLoading: false,
  locationGalleryError: null,
  locationOptions: [],
  locationOptionsLoading: true,
  locationOptionsError: null,
  selectedLocation: Number(localStorage.getItem('selectedLocation')) ?? null,
  locationsForSearch: [],
  locationForSearchLoading: false,
  locationForSearchError: null,
  locationSortOrdersLoading: false,
  locationSortOrdersError: null,
  locationSortOrdersSuccess: false,
};

const fetchLocationsStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationsLoading: true,
});

const fetchLocationsSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationsList: action.locationsList,
  locationsLoading: false,
  locationsError: null,
  locationsListUpdateNeeded: false,
});

const fetchLocationsFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationsError: action.locationsError,
  locationsLoading: false,
});

const fetchLocationSearchFilterStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationSearchFilterLoading: true,
});

const fetchLocationSearchFilterSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationSearchFilter: action.locationSearchFilter,
  locationSearchFilterLoading: false,
  locationSearchFilterError: null,
});

const fetchLocationSearchFilterFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationSearchFilterError: action.locationSearchFilterError,
  locationSearchFilterLoading: false,
});

const searchLocationsStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  searchLocationsLoading: true,
  locationsForSearch: [],
});

const searchLocationsSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  searchLocations: action.searchLocations,
  searchLocationsLoading: false,
  searchLocationsError: null,
});

const searchLocationsFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  searchLocationsError: action.searchLocationsError,
  searchLocationsLoading: false,
});

const setLocationsSearchParams = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  searchLocationsParams: action.searchLocationsParams,
});

const fetchLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationLoading: true,
  locationCreateSuccess: false,
  locationCreateError: null,
});

const fetchLocationSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  location: action.location,
  locationLoading: false,
  locationError: null,
});

const fetchLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationError: action.locationError,
  locationLoading: false,
});

const fetchLocationForSearchStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationForSearchLoading: true,
});

const fetchLocationForSearchSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationsForSearch: [...state.locationsForSearch, action.locationForSearch],
  locationForSearchLoading: false,
});

const fetchLocationForSearchFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationForSearchError: action.locationForSearchError,
  locationForSearchLoading: false,
});

const fetchPublicLocationStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  publicLocationLoading: true,
});

const fetchPublicLocationSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publicLocation: action.publicLocation,
  publicLocationLoading: false,
  publicLocationError: null,
});

const fetchPublicLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publicLocationError: action.publicLocationError,
  publicLocationLoading: false,
});

const fetchLocationGalleryStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationGalleryLoading: true,
});

const fetchLocationGallerySuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationGallery: action.locationGallery,
  locationGalleryLoading: false,
  locationGalleryError: null,
});

const fetchLocationGalleryFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationGalleryError: action.locationGalleryError,
  locationGalleryLoading: false,
});

const fetchLocationOptionsStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationOptionsLoading: true,
});

const fetchLocationOptionsSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationOptions: action.locationOptions,
  locationOptionsLoading: false,
  locationOptionsError: null,
});

const fetchLocationOptionsFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationOptionsError: action.locationOptionsError,
  locationOptionsLoading: false,
});

const createLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationCreateLoading: true,
});

const createLocationSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationCreateLoading: false,
  locationCreateError: null,
  locationCreateSuccess: true,
  createdLocation: action.createdLocation,
});

const createLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationCreateLoading: false,
  locationCreateError: action.locationCreateError,
});

const updateLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationUpdateLoading: true,
  locationUpdateSuccess: false,
});

const updateLocationSuccess = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationUpdateLoading: false,
  locationUpdateError: null,
  locationUpdateSuccess: true,
});

const updateLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationUpdateLoading: false,
  locationUpdateError: action.locationUpdateError,
});

const copyLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationCopyLoading: true,
  locationCopySuccess: false,
});

const copyLocationSuccess = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationCopyLoading: false,
  locationCopyError: null,
  locationCopySuccess: true,
  locationsListUpdateNeeded: true,
});

const copyLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationCopyLoading: false,
  locationCopyError: action.locationCopyError,
});

const deleteLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationDeleteLoading: true,
});

const deleteLocationSuccess = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationDeleteLoading: false,
  locationDeleteError: null,
  locationDeleteSuccess: true,
  locationsListUpdateNeeded: true,
});

const deleteLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationDeleteLoading: false,
  locationDeleteError: action.locationDeleteError,
});

const selectLocation = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => {
  localStorage.setItem(
    'selectedLocation',
    action.selectedLocation?.toString() ?? '',
  );

  return {
    ...state,
    selectedLocation: action.selectedLocation,
  };
};

const setLocationSortOrdersStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationSortOrdersLoading: true,
  locationSortOrdersSuccess: false,
});

const setLocationSortOrdersSuccess = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationSortOrdersLoading: false,
  locationSortOrdersError: null,
  locationsListUpdateNeeded: true,
  locationSortOrdersSuccess: true,
});

const setLocationSortOrdersFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationSortOrdersError: action.locationSortOrdersError,
  locationSortOrdersSuccess: false,
  locationSortOrdersLoading: false,
});

const resetLocationStore = (state: LocationStateType): LocationStateType => ({
  ...initialState,
  selectedLocation: state.selectedLocation,
});

const resetLocationsSearch = (state: LocationStateType): LocationStateType => ({
  ...state,
  searchLocations: [],
  searchLocationsError: null,
  searchLocationsLoading: false,
});

const completelyResetLocationStore = (): LocationStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: LocationActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATIONS_START:
      return fetchLocationsStart(state);
    case actionTypes.FETCH_LOCATIONS_SUCCESS:
      return fetchLocationsSuccess(state, action);
    case actionTypes.FETCH_LOCATIONS_FAIL:
      return fetchLocationsFail(state, action);
    case actionTypes.FETCH_LOCATION_SEARCH_FILTER_START:
      return fetchLocationSearchFilterStart(state);
    case actionTypes.FETCH_LOCATION_SEARCH_FILTER_SUCCESS:
      return fetchLocationSearchFilterSuccess(state, action);
    case actionTypes.FETCH_LOCATION_SEARCH_FILTER_FAIL:
      return fetchLocationSearchFilterFail(state, action);
    case actionTypes.SEARCH_LOCATIONS_START:
      return searchLocationsStart(state);
    case actionTypes.SEARCH_LOCATIONS_SUCCESS:
      return searchLocationsSuccess(state, action);
    case actionTypes.SEARCH_LOCATIONS_FAIL:
      return searchLocationsFail(state, action);
    case actionTypes.SET_LOCATIONS_SEARCH_PARAMS:
      return setLocationsSearchParams(state, action);
    case actionTypes.FETCH_LOCATION_START:
      return fetchLocationStart(state);
    case actionTypes.FETCH_LOCATION_SUCCESS:
      return fetchLocationSuccess(state, action);
    case actionTypes.FETCH_LOCATION_FAIL:
      return fetchLocationFail(state, action);
    case actionTypes.FETCH_LOCATION_FOR_SEARCH_START:
      return fetchLocationForSearchStart(state);
    case actionTypes.FETCH_LOCATION_FOR_SEARCH_SUCCESS:
      return fetchLocationForSearchSuccess(state, action);
    case actionTypes.FETCH_LOCATION_FOR_SEARCH_FAIL:
      return fetchLocationForSearchFail(state, action);
    case actionTypes.FETCH_PUBLIC_LOCATION_START:
      return fetchPublicLocationStart(state);
    case actionTypes.FETCH_PUBLIC_LOCATION_SUCCESS:
      return fetchPublicLocationSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_LOCATION_FAIL:
      return fetchPublicLocationFail(state, action);
    case actionTypes.FETCH_LOCATION_GALLERY_START:
      return fetchLocationGalleryStart(state);
    case actionTypes.FETCH_LOCATION_GALLERY_SUCCESS:
      return fetchLocationGallerySuccess(state, action);
    case actionTypes.FETCH_LOCATION_GALLERY_FAIL:
      return fetchLocationGalleryFail(state, action);
    case actionTypes.FETCH_LOCATION_OPTIONS_START:
      return fetchLocationOptionsStart(state);
    case actionTypes.FETCH_LOCATION_OPTIONS_SUCCESS:
      return fetchLocationOptionsSuccess(state, action);
    case actionTypes.FETCH_LOCATION_OPTIONS_FAIL:
      return fetchLocationOptionsFail(state, action);
    case actionTypes.CREATE_LOCATION_START:
      return createLocationStart(state);
    case actionTypes.CREATE_LOCATION_SUCCESS:
      return createLocationSuccess(state, action);
    case actionTypes.CREATE_LOCATION_FAIL:
      return createLocationFail(state, action);
    case actionTypes.UPDATE_LOCATION_START:
      return updateLocationStart(state);
    case actionTypes.UPDATE_LOCATION_SUCCESS:
      return updateLocationSuccess(state);
    case actionTypes.UPDATE_LOCATION_FAIL:
      return updateLocationFail(state, action);
    case actionTypes.COPY_LOCATION_START:
      return copyLocationStart(state);
    case actionTypes.COPY_LOCATION_SUCCESS:
      return copyLocationSuccess(state);
    case actionTypes.COPY_LOCATION_FAIL:
      return copyLocationFail(state, action);
    case actionTypes.DELETE_LOCATION_START:
      return deleteLocationStart(state);
    case actionTypes.DELETE_LOCATION_SUCCESS:
      return deleteLocationSuccess(state);
    case actionTypes.DELETE_LOCATION_FAIL:
      return deleteLocationFail(state, action);
    case actionTypes.SELECT_LOCATION:
      return selectLocation(state, action);
    case actionTypes.SET_LOCATION_SORT_ORDER_START:
      return setLocationSortOrdersStart(state);
    case actionTypes.SET_LOCATION_SORT_ORDER_SUCCESS:
      return setLocationSortOrdersSuccess(state);
    case actionTypes.SET_LOCATION_SORT_ORDER_FAIL:
      return setLocationSortOrdersFail(state, action);
    case actionTypes.RESET_LOCATION_STORE:
      return resetLocationStore(state);
    case actionTypes.RESET_LOCATIONS_SEARCH:
      return resetLocationsSearch(state);
    case actionTypes.LOGOUT:
      return completelyResetLocationStore();
    default:
      return state;
  }
};

export default reducer;

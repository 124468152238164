export const FETCH_LOCATIONS_START = 'FETCH_LOCATIONS_START';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAIL = 'FETCH_LOCATIONS_FAIL';

export const FETCH_LOCATION_SEARCH_FILTER_START =
  'FETCH_LOCATION_SEARCH_FILTER_START';
export const FETCH_LOCATION_SEARCH_FILTER_SUCCESS =
  'FETCH_LOCATION_SEARCH_FILTER_SUCCESS';
export const FETCH_LOCATION_SEARCH_FILTER_FAIL =
  'FETCH_LOCATION_SEARCH_FILTER_FAIL';

export const SEARCH_LOCATIONS_START = 'SEARCH_LOCATIONS_START';
export const SEARCH_LOCATIONS_SUCCESS = 'SEARCH_LOCATIONS_SUCCESS';
export const SEARCH_LOCATIONS_FAIL = 'SEARCH_LOCATIONS_FAIL';

export const SET_LOCATIONS_SEARCH_PARAMS = 'SET_LOCATIONS_SEARCH_PARAMS';

export const FETCH_LOCATION_START = 'FETCH_LOCATION_START';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export const FETCH_LOCATION_FAIL = 'FETCH_LOCATION_FAIL';

export const FETCH_LOCATION_FOR_SEARCH_START =
  'FETCH_LOCATION_FOR_SEARCH_START';
export const FETCH_LOCATION_FOR_SEARCH_SUCCESS =
  'FETCH_LOCATION_FOR_SEARCH_SUCCESS';
export const FETCH_LOCATION_FOR_SEARCH_FAIL = 'FETCH_LOCATION_FOR_SEARCH_FAIL';

export const FETCH_PUBLIC_LOCATION_START = 'FETCH_PUBLIC_LOCATION_START';
export const FETCH_PUBLIC_LOCATION_SUCCESS = 'FETCH_PUBLIC_LOCATION_SUCCESS';
export const FETCH_PUBLIC_LOCATION_FAIL = 'FETCH_PUBLIC_LOCATION_FAIL';

export const FETCH_LOCATION_GALLERY_START = 'FETCH_LOCATION_GALLERY_START';
export const FETCH_LOCATION_GALLERY_SUCCESS = 'FETCH_LOCATION_GALLERY_SUCCESS';
export const FETCH_LOCATION_GALLERY_FAIL = 'FETCH_LOCATION_GALLERY_FAIL';

export const FETCH_LOCATION_OPTIONS_START = 'FETCH_LOCATION_OPTIONS_START';
export const FETCH_LOCATION_OPTIONS_SUCCESS = 'FETCH_LOCATION_OPTIONS_SUCCESS';
export const FETCH_LOCATION_OPTIONS_FAIL = 'FETCH_LOCATION_OPTIONS_FAIL';

export const CREATE_LOCATION_START = 'CREATE_LOCATION_START';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAIL = 'CREATE_LOCATION_FAIL';

export const UPDATE_LOCATION_START = 'UPDATE_LOCATION_START';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAIL = 'UPDATE_LOCATION_FAIL';

export const COPY_LOCATION_START = 'COPY_LOCATION_START';
export const COPY_LOCATION_SUCCESS = 'COPY_LOCATION_SUCCESS';
export const COPY_LOCATION_FAIL = 'COPY_LOCATION_FAIL';

export const DELETE_LOCATION_START = 'DELETE_LOCATION_START';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAIL = 'DELETE_LOCATION_FAIL';

export const SET_LOCATION_SORT_ORDER_START = 'SET_LOCATION_SORT_ORDER_START';
export const SET_LOCATION_SORT_ORDER_SUCCESS =
  'SET_LOCATION_SORT_ORDER_SUCCESS';
export const SET_LOCATION_SORT_ORDER_FAIL = 'SET_LOCATION_SORT_ORDER_FAIL';

export const SELECT_LOCATION = 'SELECT_LOCATION';

export const RESET_LOCATION_STORE = 'RESET_LOCATION_STORE';
export const RESET_LOCATIONS_SEARCH = 'RESET_LOCATIONS_SEARCH';

export const LOGOUT = 'LOGOUT';

export type LocationActionTypes =
  | typeof FETCH_LOCATIONS_START
  | typeof FETCH_LOCATIONS_SUCCESS
  | typeof FETCH_LOCATIONS_FAIL
  | typeof FETCH_LOCATION_SEARCH_FILTER_START
  | typeof FETCH_LOCATION_SEARCH_FILTER_SUCCESS
  | typeof FETCH_LOCATION_SEARCH_FILTER_FAIL
  | typeof SEARCH_LOCATIONS_START
  | typeof SEARCH_LOCATIONS_SUCCESS
  | typeof SEARCH_LOCATIONS_FAIL
  | typeof SET_LOCATIONS_SEARCH_PARAMS
  | typeof FETCH_LOCATION_START
  | typeof FETCH_LOCATION_SUCCESS
  | typeof FETCH_LOCATION_FAIL
  | typeof FETCH_LOCATION_FOR_SEARCH_START
  | typeof FETCH_LOCATION_FOR_SEARCH_SUCCESS
  | typeof FETCH_LOCATION_FOR_SEARCH_FAIL
  | typeof FETCH_PUBLIC_LOCATION_START
  | typeof FETCH_PUBLIC_LOCATION_SUCCESS
  | typeof FETCH_PUBLIC_LOCATION_FAIL
  | typeof FETCH_LOCATION_GALLERY_START
  | typeof FETCH_LOCATION_GALLERY_SUCCESS
  | typeof FETCH_LOCATION_GALLERY_FAIL
  | typeof FETCH_LOCATION_OPTIONS_START
  | typeof FETCH_LOCATION_OPTIONS_SUCCESS
  | typeof FETCH_LOCATION_OPTIONS_FAIL
  | typeof CREATE_LOCATION_START
  | typeof CREATE_LOCATION_SUCCESS
  | typeof CREATE_LOCATION_FAIL
  | typeof UPDATE_LOCATION_START
  | typeof UPDATE_LOCATION_SUCCESS
  | typeof UPDATE_LOCATION_FAIL
  | typeof COPY_LOCATION_START
  | typeof COPY_LOCATION_SUCCESS
  | typeof COPY_LOCATION_FAIL
  | typeof DELETE_LOCATION_START
  | typeof DELETE_LOCATION_SUCCESS
  | typeof DELETE_LOCATION_FAIL
  | typeof SET_LOCATION_SORT_ORDER_START
  | typeof SET_LOCATION_SORT_ORDER_SUCCESS
  | typeof SET_LOCATION_SORT_ORDER_FAIL
  | typeof SELECT_LOCATION
  | typeof RESET_LOCATION_STORE
  | typeof RESET_LOCATIONS_SEARCH
  | typeof LOGOUT;
